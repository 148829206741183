@media only screen and (max-width: 1024px) {
  .logout-button-primary {
      display: block;
  }
  .logout-button-secondary {
      display: none;
  }
}
@media not screen and (max-width: 1024px) {
  .logout-button-primary {
    display: none;
  }
  .logout-button-secondary {
    display: block;
  }
}